import  {createSlice,configureStore} from '@reduxjs/toolkit'


const counterSliceB =createSlice({
    name:"counterB",
    initialState:{
        name:"B",
        count:2,
    },
    reducers:{
        incremented:(state)=>{
            state.count+=1
        },
        changeName:(state,parody)=>{
            state.name = parody.payload.name
        },
        setName:(state,parody)=>{
            state.name =parody.payload.name
        }
    }
})

export const { incremented, changeName } = counterSliceB.actions

export const storeA = configureStore({
    reducer: counterSliceB.reducer
})
export default counterSliceB.reducer;
//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';

// 初始化 Socket.IO 客户端
const socket = io('https://124.223.85.225:9523');

function App() {
    const [me, setMe] = useState(''); // 存储自己的 socket ID
    const [callFrom, setCallFrom] = useState(null); // 来电信息
    const [stream, setStream] = useState(null); // 本地媒体流
    const myVideo = useRef(); // 本地视频引用
    const userVideo = useRef(); // 远程视频引用
    const connectionRef = useRef(null); // RTCPeerConnection 引用

    // 初始化媒体流和事件监听
    useEffect(() => {
        // 获取用户的音视频流
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((currentStream) => {
                setStream(currentStream);
                myVideo.current.srcObject = currentStream;
            })
            .catch((error) => {
                console.error('Failed to get media stream:', error)
                setMe('error')
            });

        // 获取自己的 socket ID
        socket.on('connect', () => {
            if(socket.id){
                setMe(socket.id)
            }else {
                setMe('error')
            }

        });

        // 监听来电请求
        socket.on('incoming-call', ({ from, offer }) => {
            setCallFrom({ from, offer }); // 显示来电信息
        });

        // 监听对方的 answer
        socket.on('call-answered', ({ answer }) => {
            if (connectionRef.current) {
                connectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
            }
        });

        // 监听 ICE 候选信息
        socket.on('ice-candidate', ({ candidate }) => {
            if (connectionRef.current && candidate) {
                connectionRef.current.addIceCandidate(new RTCIceCandidate(candidate))
                    .catch(e => console.error('Error adding received ICE candidate:', e));
            }
        });

        // 清理 socket 连接
        return () => socket.disconnect();
    }, []);

    // 创建 RTCPeerConnection 实例
    const createPeerConnection = () => {
        const peerConnection = new RTCPeerConnection();

        // 添加本地音视频轨道
        stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));

        // 监听 ICE 候选事件，并发送给对方
        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                socket.emit('ice-candidate', { to: callFrom?.from || me, candidate: event.candidate });
            }
        };

        // 监听对方的媒体流
        peerConnection.ontrack = (event) => {
            userVideo.current.srcObject = event.streams[0];
        };

        return peerConnection;
    };

    // 发起呼叫
    const callUser = async (id) => {
        const peerConnection = createPeerConnection();
        connectionRef.current = peerConnection;

        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        // 发起呼叫并发送 offer 给对方
        socket.emit('call-user', { to: id, offer });
    };

    // 接听来电
    const answerCall = async () => {
        const peerConnection = createPeerConnection();
        connectionRef.current = peerConnection;

        // 设置远程描述为对方的 offer
        await peerConnection.setRemoteDescription(new RTCSessionDescription(callFrom.offer));
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        // 发送 answer 给呼叫方
        socket.emit('answer-call', { to: callFrom.from, answer });
        setCallFrom(null); // 清除来电提示
    };

    return (
        <div>
            <h1>My ID: {me}</h1>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                <div>
                    <h2>My Video</h2>
                    <video playsInline muted ref={myVideo} autoPlay style={{ width: '300px' }} />
                </div>
                <div>
                    <h2>User Video</h2>
                    <video playsInline ref={userVideo} autoPlay style={{ width: '300px' }} />
                </div>
            </div>

            {callFrom && (
                <div style={{ marginTop: '20px' }}>
                    <h2>Incoming Call from {callFrom.from}</h2>
                    <button onClick={answerCall}>Answer</button>
                </div>
            )}

            <div style={{ marginTop: '20px' }}>
                <input placeholder="Enter ID to Call" id="callId" style={{ marginRight: '10px' }} />
                <button onClick={() => callUser(document.getElementById('callId').value)}>Call</button>
            </div>
        </div>
    );
}

export default App;

import {createSlice} from '@reduxjs/toolkit'


const counterSliceA = createSlice({
    name: "counterA",
    initialState: {
        name: "A",
        count: 1,
        sessionMap: {} as any
    },
    reducers: {
        incremented: (state) => {
            state.count += 1
        },
        changeName: (state, parody) => {
            state.name = parody.payload.name
        },
        setMap: (state, parody) => {
            state.sessionMap[parody.payload.key] = parody.payload.value
        },
        setCount: (state, parody) => {
            state.count = parody.payload
        }
    }
})

export const {incremented, setCount, changeName, setMap} = counterSliceA.actions


export default counterSliceA.reducer;
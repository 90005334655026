// app/store.js
import { configureStore,combineReducers } from '@reduxjs/toolkit';
import  counterSliceA from  './a-redux-model'
import  counterSliceB from  './b-redux-model'
import { useDispatch } from 'react-redux'
const rootReducer = combineReducers({counterSliceA,counterSliceB})




export const store= configureStore({
    reducer: rootReducer
})
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export type RootState = ReturnType<typeof rootReducer>
export default store;
